import { Injectable } from '@angular/core';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class SlidesutilService {

  maxSlides: number = 3;
  slideWidth: number = 1000;
  width: number = $(window).width();
  constructor() {

    if (this.width > 992) {
      this.maxSlides = 1;
      this.slideWidth = 1000
    } else {
      this.maxSlides = 3;
      this.slideWidth = 375
    }
  }

  getMaxSlides() {
    return this.maxSlides;
  }

  getSlideWidth() {
    return this.slideWidth;
  }
}
