import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { info } from 'console';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { ApacService } from './apac.service';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor(
    private apac: ApacService,
    private router: Router
  ) {
    //Do nothing
  }
  socialMedia = new BehaviorSubject({});
  currentSocialMedia: Observable<any> = this.socialMedia.asObservable();

  _loading = new BehaviorSubject(false);
  loading: Observable<boolean> = this._loading.asObservable();

  countries = new ReplaySubject<string>();
  selectedLang = new ReplaySubject<string>();

  banners = new ReplaySubject<any>();

  storeList: EventEmitter<any> = new EventEmitter<any>();

  page = new BehaviorSubject(1);
  currentPage = this.page.asObservable();

  public getTodayStoreTimings(timings: any): any {
    if (timings) {
      let newday = JSON.parse(timings)
      const weekDays = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
      let weekDay = weekDays[new Date().getDay()];
      return newday[weekDay];
    } else {
      return "";
    }
  }

  public getSortStoreTimings(timings: any) {

    const sorter = {
      "mon": 1,
      "tue": 2,
      "wed": 3,
      "thu": 4,
      "fri": 5,
      "sat": 6,
      "sun": 7
    }
    let newday = JSON.parse(timings)
    let days = Object.keys(newday);

    /* days.sort((a, b) => {
      let day1 = a.toLowerCase();
      let day2 = b.toLowerCase();
      //return sorter[day1] - sorter[day2];
    }); */
  }

  endTime(timings: any) {
    if (timings.length > 0) {
      let start = timings.lastIndexOf("o");
      let end = timings.length;
      timings = timings.substring(start + 2, end)
      return timings
    } else {
      return "";
    }
  }
  getWeekOf(timings: any): number {
    let newday = JSON.parse(timings)
    return newday.length;
  }

  getCountyAndLangFor(appComp: any, selectedCountry: string) {
    appComp.countryName = selectedCountry;
    this.apac.countryNameSubject.next(appComp.countryName);
    this.getCountyAndLangDefault(appComp);
    this.router.navigate([
      appComp.countryName + "/" + appComp.localeId.split("-")[0],
    ]);
  }

  getCountyAndLangDefault(appComp: any) {
    appComp.languages.forEach((lan: any) => {
      if (lan.code == appComp.countryName) {
        this.countries.next(lan);
        this.selectedLang.next(lan.langList[0].code);
        appComp.localeId = lan.langList[0].code + "-" + appComp.countryName;
        this.apac.localeIdSubject.next(appComp.localeId.split("-")[0]);
      }
    });
  }
}


