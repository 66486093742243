<div class="partner-login-form">
    <form class="main-form" (submit)="onPartnerFormSubmit($event)">
        <h4>Partner Login</h4>
        <div class="white-container px-3 py-1 h-auto">
            <label>Email</label>
            <input
                type="text"
                value=""
                placeholder="Enter Email Here"
                class="textbox"
                required
            />
        </div>
        <p class="send-otp-btn">Send OTP</p>
        <div class="white-container px-3 py-1 h-auto">
            <label>OTP</label>
            <input
                type="text"
                value=""
                placeholder="Enter OTP Here"
                class="textbox"
                required
            />
        </div>
        <div>
            <button type="submit" disabled class="btn">Submit</button>
        </div>
    </form>
</div>
