import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GtagService {

  constructor() { }

  addscripTag(measurment_id : string) : void {
  const script =  document.createElement('script');
  script.src = "https://www.googletagmanager.com/gtag/js?id="+measurment_id;
  script.id ="gtag1"
  script.setAttribute('async','');

  const script2 =  document.createElement('script');
  script2.id = "gtag2"
  script2.innerHTML = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', '${measurment_id}');
`;
document.getElementsByTagName('body')[0].appendChild(script);
document.getElementsByTagName('body')[0].appendChild(script2);
  }

  deleteScript(){
    const script = document.querySelector('#measurment_id');
    const script2 = document.querySelector('#script2');
    if(script) script.remove();
    if(script2) script2.remove()
  }

}
