import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-partner-login',
    templateUrl: './partner-login.component.html',
    styleUrls: ['./partner-login.component.css'],
})
export class PartnerLoginComponent implements OnInit {
    constructor(private authService: AuthService) {}

    ngOnInit(): void {}

    onPartnerFormSubmit(event: any) {
        event.preventDefault();
        this.authService.login();
    }
}
