<div style="width: 300px;position: relative;" class="blogs">

</div>

<carousel [borderRadius]="20" style="z-index:1;" (click)="showPage($event)" [cellsToShow]="1" [cellWidth]="552"
    [height]="height" *ngIf="bannerImages && bannerImages.length>0">
    <div class="carousel-cell" *ngFor="let item of bannerImages">
        <img [src]="item.path" [alt]="item.alt" loading="lazy">
    </div>
</carousel>


<!-- <div class="item" id="offerplaceholder">
            <div class="full-image">
                <a href="javascript:;">
                    <img src="./assets/images/loader.gif" width="10%" height="auto" alt="" class="d-xl-block" style="margin: 0 auto;">
                </a>
            </div>
        </div> -->