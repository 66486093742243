<!-- <style>
  table, td, th {
    border: 1px solid white;
   
    color:white;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  </style> -->
<app-header *ngIf="offsetFlag"></app-header>
<div [ngStyle]="source?{'visibility':'visible','margin-top':'0'}:{'visibility':'hidden','margin-top':'0'}" id="fullPage"
  (scroll)="onScroll($event)">
  <!-- section 1-->
  <div class="section" data-anchor="section1" id="sectionnn1">

    <div class="spotlight">
      <div class="banner banner1 mobile-banner" *ngIf="isDesktop"
        [ngStyle]="{'background-image':'url('+'/rest/service/images/source/'+source?.sourceId+'/name/'+source?.data.mainbanner.image+')'}">
        <div class="banner-content">
          <h2 class="font-gotham-bold color-white">{{source?.data.mainbanner.title}}</h2>
          <p>{{source?.data.mainbanner.caption}}</p>
          <div class="btn-sec" *ngIf="source?.data.mainbanner.actions">
            <a *ngFor="let action of source?.data.mainbanner.actions" [href]="action.link" target="_blank"
              class="btn bg-color-primery color-white mr-2 ">{{action.title}}</a>

          </div>
        </div>
      </div>

      <div class="banner banner2 mobile-banner" *ngIf="!isDesktop"
        [ngStyle]="{'background-image':'url('+'/rest/service/images/source/'+source?.sourceId+'/name/'+source?.data.mainbanner.mobilemage+')'}">
        <div class="banner-content">
          <h2 class="font-gotham-bold color-white">{{source?.data.mainbanner.title}}</h2>
          <p>{{source?.data.mainbanner.caption}}</p>
          <div class="btn-sec" *ngIf="source?.data.mainbanner.actions">
            <a *ngFor="let action of source?.data.mainbanner.actions" [href]="action.link" target="_blank"
              class="btn bg-color-primery color-white mr-2 mt-2">{{action.title}}</a>
            <!-- <a href="https://www.buyalenovo.com" target="_blank" class="btn bg-color-primery color-white"> Looking to
              Buy?</a> -->
          </div>
        </div>
      </div>

    </div>

  </div>
  <!-- section 2-->
  <div class="section" data-anchor="section2" id="sectionnn2" #target1>
    <div class="callback-sec">
      <div class="container pt-5 pb-5">
        <div class="row">
          <div class="col-md-6 col-4 d-flex align-items-center pl-0">
            <div class="callback-cont">
              <h2 class="p-3"> {{source?.data.callback.title}} </h2>
              <p class="mt-3">{{source?.data.callback.caption}}</p>
            </div>
          </div>
          <div class="col-md-6 col-8">
            <div class="form-sec mt-3">
              <form [formGroup]="leadCaptureForm" (ngSubmit)="saveLead()">
                <p class="text-center"><b class="bg-transparent">Do fill the below form</b></p>
                <div class="form-group mb-4 padded-field">
                  <span class="form-icon"> <i class="far fa-user"></i> </span>
                  <input type="text" placeholder="Name" formControlName="name" (keypress)="textOnly($event)" />
                  <span
                    *ngIf="(leadCaptureForm.controls.name.errors?.required && formsubmited) || (leadCaptureForm.controls.name.touched && leadCaptureForm.controls.name.errors?.required)"
                    class="errMsg">
                    Name is required
                  </span>
                </div>
                <div class="form-group mb-4 padded-field">
                  <span class="form-icon"> <i class="far fa-envelope"></i> </span>
                  <input type="email" placeholder="Email ID" formControlName="email" />
                  <span *ngIf="(leadCaptureForm.controls.email.errors?.email && formsubmited) || 
                  (leadCaptureForm.controls.email.touched && leadCaptureForm.controls.email.errors?.email) "
                    class="errMsg">
                    Invalid email
                  </span>
                  <span *ngIf="(leadCaptureForm.controls.email.errors?.required && formsubmited) || 
                   (leadCaptureForm.controls.email.touched && leadCaptureForm.controls.email.errors?.required)"
                    class="errMsg">
                    Email is required
                  </span>
                </div>
                <div class="form-group mb-4 padded-field">
                  <span class="form-icon"> <i class="fas fa-mobile-alt"></i> </span>
                  <input type="text" pattern="\d*" minlength="10" maxlength="10" placeholder="Mobile"
                    formControlName="mobileNo" (keypress)="numericOnly($event)" />
                  <span
                    *ngIf="((leadCaptureForm.controls.mobileNo.errors?.min || leadCaptureForm.controls.mobileNo.errors?.max) && formsubmited) || 
                  (leadCaptureForm.controls.mobileNo.touched && (leadCaptureForm.controls.mobileNo.errors?.min || leadCaptureForm.controls.mobileNo.errors?.max))"
                    class="errMsg">
                    Invalid mobile no.
                  </span>
                  <span *ngIf="(leadCaptureForm.controls.mobileNo.errors?.required && formsubmited) || 
                  (leadCaptureForm.controls.mobileNo.touched && leadCaptureForm.controls.mobileNo.errors?.required)"
                    class="errMsg">
                    Mobile no is required
                  </span>
                </div>
                <div class="form-group mb-4 padded-field">
                  <span class="form-icon"> <i class="fas fa-map-marker-alt"></i> </span>
                  <input type="text" pattern="\d*" maxlength="6" placeholder="Pincode" formControlName="pincode"
                    (keypress)="numericOnly($event)" />
                  <span
                    *ngIf="((leadCaptureForm.controls.pincode.errors?.min || leadCaptureForm.controls.pincode.errors?.max) && formsubmited) || 
                  (leadCaptureForm.controls.pincode.touched && (leadCaptureForm.controls.pincode.errors?.min || leadCaptureForm.controls.pincode.errors?.max)) "
                    class="errMsg">
                    Invalid pincode
                  </span>

                  <span *ngIf="(leadCaptureForm.controls.pincode.errors?.required && formsubmited) || 
                  (leadCaptureForm.controls.pincode.touched && leadCaptureForm.controls.pincode.errors?.required)"
                    class="errMsg">
                    Pincode is required
                  </span>
                </div>
                <div class="form-group"><input id="bookADemo" type="checkbox" formControlName="bookADemo"
                    style="width:auto;"> I am interested in
                  a demo
                </div>
                <div class="form-group">
                  <button type="submit" class="bg-color-primery color-white btn" id="btnForm"> Submit </button>
                </div>
                <p class="fs-14 mb-0"> Submitting this form allows us to call you back! </p>
              </form>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>


  <!-- section 3-->
  <div class="section" data-anchor="section3" id="sectionnn3" #target>
    <div class="callback-sec">
      <div class="container pt-5 pb-5">
        <div class="row flex-row-reverse mt-2">
          <div class="col-md-6 col-4 d-flex align-items-start">
            <div class="callback-cont mr-5 mt-0">
              <h2 class="p-3"> {{source?.data.locateus.title}} </h2>
              <p class="mb-2 px-3">{{source?.data.locateus.caption}}</p>
              <div class="location-white-bx-lp">
                <div class="locate-me-txt"><span><a
                      (click)="getstorebylocation()">{{currentCity?currentCity:'India'}}</a></span></div>
                <div class="search-location d-flex align-items-center">
                  <i class="fas fa-map-marker-alt"></i>
                  <!-- <button #searchPlacesButton style="position: absolute; right: 0; margin-right: 10px;" (click)="searchPlaces()">Search</button> -->
                  <input id="address" (click)="setAddressAreaBack()" autocomplete="off" type="text"
                    placeholder="Search Location" [(ngModel)]="selectedPlace" class="textbox" autocomplete="false"
                    (keydown)="startSearch($event)">
                  <span *ngIf="locationflag" class="errMsg">
                    Please enable permission to access your location
                  </span>

                  <div class="suggetions-wrapper">
                    <ul>
                      <li class="fix"><a href="#">Please enable permission to access your location or type your location
                          in the search box above.</a></li>

                      <li *ngFor="let place of places"><a
                          style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;font-size: 0.9em;"
                          (click)="selectPlace(place)">{{place.description}}</a></li>

                      <!--                       
                      <li><a href="#">Mumbai
                        </a></li>
                      <li><a href="#">Delhi
                        </a></li> -->
                      <li class="get-location"
                        style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;font-size: 0.9em;"><a
                          href="javascript:" (click)="getstorebylocation()"><i class="fas fa-location-arrow"></i> Use my
                          current
                          location</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="addressHolder" class="col-md-6 col-8"
            [ngStyle]="stores && stores.length>0?{'visibility':'visible'}:{'visibility':'hidden'}">
            <app-landing-page-addresses #storesPlaceholder [sourceId]="source.sourceId" [stores]="stores"
              *ngIf="stores && stores.length>0"></app-landing-page-addresses>
            <!-- <div class="direction-slider location-enable p-4" id="address-slider">
              <ul #nearestStoresHolder id="nearestStoresHolder">
                <li *ngFor="let store of stores">
                  <div class="form-sec">
                    <p class="fs-18"><b class="bg-transparent"> {{store.name}} </b></p>
                    <div class="googlerating"><img [src]="googleIcon"> <span
                        class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star"></span>
                      <span class="fa fa-star"></span>4.5</div><br>
                    <p class="fs-16">{{store.address}}, {{store.city}}, {{store.state}}</p>
                    <p class="fs-16"> <i class="fas fa-clock"></i> Open until 9:00 PM </p>
                    <p class="fs-16"> <i class="fas fa-directions"></i> 1.40 KM away</p>
                    <div class="icon-sec mt-4">
                      <ul class="d-flex">
                        <li class="mr-4"><a href="#" class="d-flex"> <i class="fas fa-phone-alt"></i> Call</a></li>
                        <li class="mr-4"><a href="#" class="d-flex"> <i class="fas fa-desktop"></i> Book a<br>Demo</a>
                        </li>
                        <li><a href="#" class="d-flex"> <i class="fas fa-store"></i> View Store<br>Details</a></li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="form-sec">
                    <p class="fs-18"><b class="bg-transparent"> Lenovo Exclusive Store - Absolute IT </b></p>
                    <div class="googlerating"><img [src]="googleIcon"> <span
                        class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star"></span>
                      <span class="fa fa-star"></span> 4.5</div><br>
                    <p class="fs-16">Store No UG -39B, Upper Ground Floor, Phoenix market City mall,
                      LBS Marg, Kurla - West
                      Mumbai City, Maharashtra.</p>
                    <p class="fs-16"> <i class="fas fa-clock"></i> Open until 9:00 PM </p>
                    <p class="fs-16"> <i class="fas fa-directions"></i> 1.40 KM away</p>
                    <div class="icon-sec mt-4">
                      <ul class="d-flex">
                        <li class="mr-4"><a href="#" class="d-flex"> <i class="fas fa-phone-alt"></i> Call</a></li>
                        <li class="mr-4"><a href="#" class="d-flex"> <i class="fas fa-desktop"></i> Book a<br>Demo</a>
                        </li>
                        <li><a href="#" class="d-flex"> <i class="fas fa-store"></i> View Store<br>Details</a></li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div> -->
            <!-- <div class="dont-allow-loaction location-disable" id="dealer-address">
                          <div class="form-sec">
                              <p class="fs-18"><img src="images/clicktolocate.png"><br>Click to view nearest store</p>
                             
                          </div>
                      </div> -->
          </div>

        </div>
      </div>
    </div>
  </div>

  <!-- section 4-->
  <div class="section" data-anchor="section4" id="sectionnn4" style="height:100vh;">




    <span class="sp-dot"><img [src]="'/rest/service/images/source/'+source?.sourceId+'/name/sp-dot.png'" alt=""
        loading="lazy"></span>
    <div class="container px-0">
      <div class="slider-navigation-bar mt-5">
        <div class="slider-nav d-flex align-items-center">
          <div *ngIf="!Isgrid">
            <p class="m-0 mr-2 fs-18 color-white"> {{"store-listing.store-site.featured-products" | translate}} <span>
                <img [src]="'/rest/service/images/source/'+source?.sourceId+'/name/arrow-right.png'" alt=""
                  loading="lazy"></span>
            </p>
          </div>
          <ul class="product-listing" *ngIf="!Isgrid">

            <li *ngFor="let product of source?.data.featuredproducts.products"><a
                style="line-height: 1.5;font-size:14px;"> {{product.name}} </a></li>

          </ul>

        </div>
      </div>

    </div>
    <div *ngIf="Isgrid" class="  Offer-upgrades table-responsive-sm " id="tb"
      style="color: yellow; pointer-events: none;" [innerHtml]="source.data.featuredproducts.overrideProductsDisplay">

    </div>
    <div class="terms" style="position: absolute;  left: 0; bottom: 0;">
      <div class="container">
        <ul class="d-flex mt-5">
          <li class="mr-5"><a
              [href]="'/rest/service/images/source/'+source?.sourceId+'/name/'+source?.data.featuredproducts.termsAndConditions.tc_link"
              target="_blank" class="color-white fs-14"><img
                [src]="'/rest/service/images/source/'+source?.sourceId+'/name/arrow-right.png'" alt=""
                loading="lazy">Terms &
              Conditions</a></li>
          <li *ngIf="!Isnewfqas"><a href={{source?.data.featuredproducts.faqs}} target="_blank"
              class="color-white fs-14"><img
                [src]="'/rest/service/images/source/'+source?.sourceId+'/name/arrow-right.png'" alt=""
                loading="lazy">FAQs</a></li>
          <li *ngIf="Isnewfqas"><a
              [href]="'/rest/service/images/source/'+source?.sourceId+'/name/'+source?.data.featuredproducts.faqs1"
              target="_blank" class="color-white fs-14"><img
                [src]="'/rest/service/images/source/'+source?.sourceId+'/name/arrow-right.png'" alt=""
                loading="lazy">FAQs</a></li>
        </ul>
      </div>
    </div>

    <div class="py-5" *ngIf="!Isgrid">
      <div class="yoga-main-slider mb-5">
        <ul>
          <li *ngFor="let product of source?.data.featuredproducts.products">
            <div class="row color-white">
              <div class="col-md-6 d-flex justify-content-end aligadj">
                <div class="yoga-slider-left">
                  <h2 class='mb-3'>{{product.name}} </h2>
                  <p><b class="bg-transparent"> {{product.caption}} </b>
                  </p>

                  <div class="yoga-buttons-sec mt-4">
                    <a href="#yoga6-popup" (click)="setproduct(product)" data-fancybox
                      class="bg-color-primery color-white btn mr-2"> View
                      details</a>
                    <a (click)="scroll(target1)" class="bg-color-primery color-white btn mr-2 get-a-call"> Get a
                      Call</a>
                  </div>

                  <div class="whtbut mt-4">
                    <a href="/bookademo/?cid=in:other:9o8v9h" target="_blank"><span>Book a Demo</span></a>
                    <a *ngIf="product.buynow" [href]="product.buynow" title="" target="_blank"><span>Buy Now</span></a>
                    <a (click)="scroll(target)" title="" class="locatestorebt"><span>Locate Store</span></a>
                  </div>

                  <div class="offer-tag mobhide" style="max-width: 300px;">
                    <div class="offer-img2 mr-3"><img
                        [src]="'/rest/service/images/source/'+source?.sourceId+'/name/'+product.offer.image"
                        loading="lazy"></div>
                    <div class="offer-para">
                      <p class="m-0 color-white fs-16"> {{product.offer.title}}</p>
                      <div class="offer-img-sec">
                        <p>{{product.offer.caption}}</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-6 d-flex align-items-center justify-content-center aligadj">
                <div class="yogas-img">
                  <img [src]="'/rest/service/images/source/'+source?.sourceId+'/name/'+product.mainImage" alt="">
                </div>
                <div class="offer-tag pt-5 desktophide">
                  <div class="offer-img2 mr-3"><img
                      [src]="'/rest/service/images/source/'+source?.sourceId+'/name/'+product.offer.image"
                      loading="lazy"></div>
                  <div class="offer-para">
                    <p class="m-0 color-white fs-16"> {{product.offer.title}}</p>
                    <div class="offer-img-sec">
                      <p>{{product.offer.caption}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

  </div>

  <!-- section 7-->
  <div class="section" data-anchor="section7" id="sectionnn7">
    <div class="bottom-section-wrapepr">
      <div class="bottom-top-section">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="warranty-cont">
                <h2 class="my-4">Warranty & Services </h2>
                <ul>
                  <li *ngFor="let warranty of source?.data.warrantyandservices.details"> <img
                      [src]="'/rest/service/images/source/'+source?.sourceId+'/name/'+warranty.icon" alt=""
                      loading="lazy"><br>
                    <p>{{warranty.title}}</p>
                  </li>
                  <!-- <li> <img [src]="w2" alt="">
                    <p>Certified Lenovo Technicians</p>
                  </li>
                  <li> <img [src]="w3" alt="">
                    <p>Access to genuine spare parts</p>
                  </li> -->
                </ul>
              </div>
            </div>
            <div class="col-md-6">
              <div class="container">
                <div class="warranty-img">
                  <img
                    [src]="'/rest/service/images/source/'+source?.sourceId+'/name/'+source?.data.warrantyandservices.image"
                    alt="" loading="lazy">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-landing-page-footer></app-landing-page-footer>
      <app-footer></app-footer>
    </div>
  </div>
</div>



<!-- terms -->

<section class="popup-sec" style="display: none;" id="terms-cond-popup">
  <div class="container">
    <div class="popup-main text-left">
      <div class="pop-terms py-5">
        <!-- <h2 class="color-primery mb-3"> {{source?.data.featuredproducts.termsAndConditions.title}}</h2>
        <p class="fs-16"> <b class="bg-transparent">{{source?.data.featuredproducts.termsAndConditions.header}} </b> </p>
        <p class="fs-16"> {{source?.data.featuredproducts.termsAndConditions.subHeader}}</p> -->

        <embed src="https://buyalenovo.com/rest/service/gettc/333#toolbar=0&zoom=80" width="100%" height="700"
          type="application/pdf">

        <!-- <div *ngFor="let pnt of source?.data.featuredproducts.termsAndConditions.content">
          <p class="fs-16"> <b class="bg-transparent">{{pnt.header}}</b> </p>
          <ul *ngFor="let point of pnt.points">
            <li > {{point.point }} 
            
        </li>  
        
        
      </ul>
        </div> -->


        <ul>
          <!-- <li *ngFor="let point of source?.data.featuredproducts.termsAndConditions.content.points"> {{point}}
          </li>  -->

        </ul>
      </div>
    </div>
  </div>
</section>
<!-- end -->



<!-- product popup -->

<section *ngIf="selectedproduct" class="popup-sec" style="display: none;" id="yoga6-popup">
  <div class="container">
    <div class="popup-main text-center">
      <div class="top-img-se">
        <img [src]="'/rest/service/images/source/'+source?.sourceId+'/name/'+selectedproduct.detail.image" alt=""
          loading="lazy">
      </div>
      <div class="top-img-title pt-4">
        <p class="color-primery fs-28"> {{selectedproduct.caption}} </p>
      </div>
      <div class="yogas-img mt-5">
        <img [src]="'/rest/service/images/source/'+source?.sourceId+'/name/'+selectedproduct.mainImage" alt=""
          loading="lazy">
      </div>
      <div class="bottom-desc pt-4">
        <div class="row">
          <div class="col-md-3 col-6" *ngFor="let dtls of selectedproduct.detail.details">
            <div class="bottom-card">
              <img [src]="'/rest/service/images/source/'+source?.sourceId+'/name/'+dtls.icon" alt="" loading="lazy">
              <p class="mt-4 fs-18 color-primery font-gotham-bold" style="font-size: 18px;"> {{dtls.title}}</p>
              <p class="fs-14 color-black" style="font-size: 14px;line-height: 21px;">{{dtls.description }} </p>
            </div>
          </div>


        </div>
      </div>
      <div class="offer-tag popupoff">
        <div class="offer-img2 mr-3"><img
            [src]="'/rest/service/images/source/'+source?.sourceId+'/name/'+selectedproduct.offer.image" loading="lazy">
        </div>
        <div class="offer-para">
          <p class="m-0 color-white fs-16"> {{selectedproduct.offer.title}}</p>
          <div class="offer-img-sec">
            <p>{{selectedproduct.offer.caption}}</p>
          </div>
        </div>

      </div>


      <!-- <div class="popup-offer mb-5 mt-5">
        <div class="offer-tag bg-color-primery">
          <div class="offer-img-sec mr-3">
            <p class="m-0 fs-16 color-black"> Worth<br />
              ₹4290<br />
              free </p>
          </div>
          <div class="offer-para">
            <p class="m-0 color-white fs-20"> Lenovo 110 Headset (Wired)
              + Lenovo BT 600 Mouse </p>
          </div>
        </div>
      </div> -->
      <div class="table-sec">
        <table class="table table-bordered bg-color-white">
          <ng-container *ngFor="let tbl of selectedproduct.detail.specs;let i=index">
            <tr *ngIf="i%2===0">

              <td> {{selectedproduct.detail.specs[i].name}} </td>
              <td> {{selectedproduct.detail.specs[i].value}} </td>

              <td> {{selectedproduct.detail.specs[i+1].name? selectedproduct.detail.specs[i+1].name:""}} </td>
              <td> {{selectedproduct.detail.specs[i+1].value? selectedproduct.detail.specs[i+1].value:""}} </td>



            </tr>
          </ng-container>


        </table>
      </div>
    </div>
  </div>
</section>

<!-- product popup end -->

<!-- Form popup thanks-->
<section class="popup-sec" style="display: none;" id="popupthanks">
  <div class="container">
    <div class="popup-main text-center">
      <h2>{{leadCreationMessage}}</h2>
      <p class="pt-4">{{leadCreationSubMessage}}</p>
    </div>
  </div>
</section>
<!-- Form popup -->