<div class="ratingnum" [ngClass]="[].constructor(ratingLength).length == 5?'row justify-content-between  ml-0 mr-0 ':'row justify-content-center align-items-center '">
   <div 
   [ngClass]="[].constructor(ratingLength).length == 5?'col-2 text-center':'col-sm-1 col-xs-1 col-1 pl-0 pr-0 text-center'"
   *ngFor="let item of [].constructor(ratingLength); let i=index">
   <p class="pb-1" >
      {{[].constructor(ratingLength).length == 5 ? i+1 : i }} 
   </p>
</div>
</div>

<div [ngClass]="[].constructor(ratingLength).length == 5?'row justify-content-between emojisection ml-0 mr-0 ':'row justify-content-center align-items-center emojisection'">
   <div [ngClass]="[].constructor(ratingLength).length == 5?'col-2 text-center pl-0 pr-0 pt-2 pb-2':' col-1  pl-0 pr-0 text-center  pt-2 pb-2'"  
   *ngFor="let item of [].constructor(ratingLength); let i=index" >
   <div [ngClass]="[].constructor(ratingLength).length == 5?'emoji_bg emoji_bg_five mx-auto rate-num'+(i+1):'emoji_bg mx-auto rate-num'+ratingStringTen[i]"   (click)="selectedRating(i+1,$event)">
</div>
</div> 
</div>

<div class="ratingString" [ngClass]="[].constructor(ratingLength).length == 5?'row justify-content-between  ml-0 mr-0 ':'row justify-content-center align-items-center'">
   <div [ngClass]="[].constructor(ratingLength).length == 5?'col-2 text-center pt-2 pb-2 pl-0 pr-0 m-0'
   :' text-center text-nowrap pt-2 pb-2 pl-0 pr-0 col-'+filterValue(i+1)"  *ngFor="let item of ratingString; let i=index">
<div>
   <p class="pt-1">
      {{item}}
   </p>
   </div>
</div>

</div>

<!--[style.background-image]="'url(../../assets/images/'+item+')'"-->

