import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-popup-survey',
  templateUrl: './popup-survey.component.html',
  styleUrls: ['./popup-survey.component.css']
})
export class PopupSurveyComponent implements OnInit {

  @Output() selectedOption = new EventEmitter();
  @Input("_for") _for = "desktop";
  checkedcolor = "#1B2B81";
  uncheckedcolor = "#e3e4f0";
  ratingString:String[] = [];
  currentItem:number = 5;
  currentItem1:number = 11;
  ratingString1:string[] = [];

  constructor() { }

  ngOnInit(): void {
    this.ratingString=["Very Poor","Poor","Average","Good","Very Good"];
  }


  initSurvey(val: any) {
    this.selectedOption.emit(val);
  }

}
