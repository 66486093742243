import { Title } from '@angular/platform-browser';
import { LandingPageAddressesComponent } from './landing-page-addresses/landing-page-addresses.component';
import { CarouselComponent } from 'angular-responsive-carousel';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { StoresRequest, SimpleStoresRequest } from './../models/store';
import { take } from 'rxjs/operators';
import { GeolocationService, GEOLOCATION_SUPPORT } from '@ng-web-apis/geolocation';
import { AppComponent } from './../app.component';
import { ApiService } from '../services/api.service';
import { ActivatedRoute, Router, NavigationEnd, Event } from '@angular/router';
import { Component, OnInit, AfterViewInit, Inject, ViewChild, ElementRef, Input } from '@angular/core';
import { Location } from '../models/location';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { async } from '@angular/core/testing';
import { StarRatingComponent } from 'ng-starrating'
import { HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css'],
  styles: [`
    ::ng-deep .product-listing .slick-track{
      display: flex !important;
      width: auto !important;
      justify-content: flex-start !important;
      flex-wrap: wrap !important;
    }
  `, `
  ::ng-deep .product-listing .slick-slide{
    width:auto !important;
    padding-bottom: 3px;
  }
  `,
    `
  ::ng-deep #tb td, th{
    border: 1px solid white;
    font-size: 14px;
 
    color:white;
  }
  `, `
  ::ng-deep #tb th {
    font-weight: bold;
    font-size: 16px;
    color:white;
  }
  `,
    `
  ::ng-deep .table{
    width: 100%;
    border-collapse: collapse;
  }
  `
  ]
})
export class LandingPageComponent implements OnInit, AfterViewInit {

  source: any;
  nearestStores: any = [];
  storesSubscription?: any;
  currentLocation = new ReplaySubject<Location>();
  city: any;
  stores: any = [];
  formControl: FormControl = new FormControl();
  leadCreationMessage?: string;
  leadCreationSubMessage?: string;
  currentCity?: string;
  selectedPlace: any = [];
  selectedproduct: any;
  searchString: any = '';
  offsetFlag: boolean = true;
  locationflag: boolean = false;
  locationstatus: boolean = false;
  Isgrid: boolean = false;
  Isnewfqas: boolean = false;
  formsubmited: boolean = false;


  @ViewChild("nearestStoresHolder") nearestStoresHolder?: ElementRef;
  @ViewChild("searchPlacesButton") searchPlacesButton?: ElementRef;
  @ViewChild("storesPlaceholder", { static: false }) storesPlaceholder?: LandingPageAddressesComponent;
  isDesktop: boolean = true;

  // url: string = 'http://localhost:7080/rest/service/images/source/100/name/2nd-sec-bg.png';
  // herobg: string = 'http://localhost:7080/rest/service/images/source/100/name/hero-bg.jpg';
  // sectionnn2: string = 'http://localhost:7080/rest/service/images/source/100/name/2nd-sec-bg.png';
  // sectionnn4: string = 'http://localhost:7080/rest/service/images/source/100/name/yoga6-bg.jpg';
  // banner1: string = 'http://localhost:7080/rest/service/images/source/100/name/spotlight1.jpg';
  // banner2: string = 'http://localhost:7080/rest/service/images/source/100/name/spotlight1.jpg';
  // googleIcon: string = 'http://localhost:7080/rest/service/images/source/100/name/google-icon.png';
  // arrowRight: string = 'http://localhost:7080/rest/service/images/source/100/name/arrow-right.png';
  // spDot: string = 'http://localhost:7080/rest/service/images/source/100/name/sp-dot.png';
  // offer: string = 'http://localhost:7080/rest/service/images/source/100/name/offer.png';
  // yoga6Laptop: string = 'http://localhost:7080/rest/service/images/source/100/name/yoga6-taptop.png';
  // yoga7Laptop: string = 'http://localhost:7080/rest/service/images/source/100/name/yoga7.png';
  // flex5: string = 'http://localhost:7080/rest/service/images/source/100/name/flex5.png';
  // ipGaming: string = 'http://localhost:7080/rest/service/images/source/100/name/ip-gaming3.png';
  // legion: string = 'http://localhost:7080/rest/service/images/source/100/name/legion.png';
  // slim5: string = 'http://localhost:7080/rest/service/images/source/100/name/slim5.png';
  // w1: string = 'http://localhost:7080/rest/service/images/source/100/name/w1-icon.png';
  // w2: string = 'http://localhost:7080/rest/service/images/source/100/name/w2-icon.png';
  // w3: string = 'http://localhost:7080/rest/service/images/source/100/name/w3-icon.png';
  // warranty: string = 'http://localhost:7080/rest/service/images/source/100/name/warranty-img.jpg';
  // yoga6SVG: string = 'http://localhost:7080/rest/service/images/source/100/name/yoga-6.svg';
  // fabricCover: string = 'http://localhost:7080/rest/service/images/source/100/name/fabric-cover.svg';
  // frontSpeakers: string = 'http://localhost:7080/rest/service/images/source/100/name/front-speakers.svg';
  // activePen: string = 'http://localhost:7080/rest/service/images/source/100/name/active-pen.svg';
  // wifi6: string = 'http://localhost:7080/rest/service/images/source/100/name/wifi6.svg';
  // yoga7SVG: string = 'http://localhost:7080/rest/service/images/source/100/name/yoga-7.svg';
  // fourSided: string = 'http://localhost:7080/rest/service/images/source/100/name/4-sided-narrow.svg';
  // dragonTail: string = 'http://localhost:7080/rest/service/images/source/100/name/dragontail-glass.svg';
  // ideapadFlex5: string = 'http://localhost:7080/rest/service/images/source/100/name/ideapad-flex5.svg';
  // privacyShutter: string = 'http://localhost:7080/rest/service/images/source/100/name/privacy-shutter.svg';
  // ideapadGaming3: string = 'http://localhost:7080/rest/service/images/source/100/name/ideapad-gaming3.svg';
  // qControl: string = 'http://localhost:7080/rest/service/images/source/100/name/q-control.svg';
  // betterGaming: string = 'http://localhost:7080/rest/service/images/source/100/name/better-gaming.svg';
  // coldFront: string = 'http://localhost:7080/rest/service/images/source/100/name/cold-front.svg';
  // trueStrike: string = 'http://localhost:7080/rest/service/images/source/100/name/true-strike.svg';
  // legion5: string = 'http://localhost:7080/rest/service/images/source/100/name/legion5.svg';
  // onefourfourmhz: string = 'http://localhost:7080/rest/service/images/source/100/name/144mhz.svg';
  // ideapadSlim5: string = 'http://localhost:7080/rest/service/images/source/100/name/ideapad-slim5.svg';
  // fingerPrint: string = 'http://localhost:7080/rest/service/images/source/100/name/finger-print.svg';

  leadCaptureForm = this.fb.group({
    name: ['', Validators.required],
    mobileNo: ['', [Validators.required, Validators.min(1000000000), Validators.max(9999999999)]],
    email: ['', [Validators.required, Validators.email]],
    pincode: ['', [Validators.required, Validators.min(100000), Validators.max(999999)]],
    bookADemo: [false]
  });
  places?: any[];

  constructor(private route: ActivatedRoute, private apiService: ApiService, private appComp: AppComponent,
    private readonly geolocation$: GeolocationService,
    @Inject(GEOLOCATION_SUPPORT) private readonly geolocationSupport: boolean, private fb: FormBuilder,
    private titleMeta: Title) {

    route.url.subscribe(async (val) => {
      if (val && val.length === 1) {
        this.apiService.getLandingPageDetails(val[0].path)
          .then(result => {
            this.source = result.source;
            this.source.data = JSON.parse(this.source.data);
            this.titleMeta.setTitle(this.source.data.pageTitle);

            if (this.source.data.featuredproducts.overrideProductsDisplay === undefined || this.source.data.featuredproducts.overrideProductsDisplay === null || this.source.data.featuredproducts.overrideProductsDisplay === "") {
              this.Isgrid = false;
              console.log("grird check");


            } else {
              console.log(this.source.data.featuredproducts.overrideProductsDisplay);

              $('.grid').append("<span></span>");
              this.Isgrid = true;
              console.log("grird check");
            }
            if (this.source.data.featuredproducts.faqs1 === undefined || this.source.data.featuredproducts.faqs1 === null || this.source.data.featuredproducts.faqs1 === "") {
              this.Isnewfqas = false
            } else {
              this.Isnewfqas = true;

            }



          });

        await this.checkLocationSetting();
        this.currentLocation.subscribe((val) => {
          console.log()
          this.apiService.getStoresBasedOnLocation(new StoresRequest(val.lat, val.lng, 2, 2, 0))

            .then((result) => {
              console.log(result);
              console.log("result.listWithinRadius" + result.listWithinRadius);
              if (result.listWithinRadius && result.listWithinRadius.length > 0) {
                this.nearestStores = result.listWithinRadius;

              }

              this.stores = this.nearestStores;
              //this.subscribeToStores();
              this.decoratePage();
            });
        });





      }

    });
  }


  getstorebylocation() {
    this.locationflag = false;
    this.currentLocation.subscribe((val) => {

      this.apiService.getStoresBasedOnLocation(new StoresRequest(val.lat, val.lng, 2, 2, 0))
        .then((result) => {
          if (result.listWithinRadius && result.listWithinRadius.length > 0) {
            this.nearestStores = result.listWithinRadius;

            //this.selectedPlace = place;

          }

          this.places = [];
          //$('#address').val('');

          this.stores = this.nearestStores;
          //this.subscribeToStores();
          //this.decoratePage();
        });

      if (this.locationstatus == false) {
        this.apiService.geocode(val.lat, val.lng).then((result) => {
          console.log("current....")
          console.log(result.results[0].formatted_address);

          this.selectedPlace = result.results[0].formatted_address;
          //alert(this.selectedPlace);

        });

      } else {
        this.locationflag = true;
      }



    });
  }

  subscribeToStores() {

    $('#nearestStoresHolder').slick('removeSlide', null, null, true);
    //$('#nearestStoresHolder').empty();
    //$("#nearestStoresHolder").slick("unslick");

    if (this.nearestStores && this.nearestStores.length > 0) {
      for (let store of this.nearestStores) {
        console.log("loopcount");

        let storeHtml: string = `<li>
            <div class="form-sec">
              <p class="fs-18 mb-3"><b class="bg-transparent">`+ store.name + `</b></p>

              <div class="googlerating d-flex align-items-center"><img class="mr-2" src="/rest/service/images/source/`+ this.source.sourceId + `/name/google-icon.png">       
              
              <ng-template> <star-rating value="3" totalstars="5"
              checkedcolor="#F2C94C" uncheckedcolor="lightgray"
              size="24px" readonly=true  #starrating>
              </star-rating></ng-template>
              
              </div><br>
              <p class="fs-16 mt-3">`+ store.address + `, ` + store.city + `, ` + store.state + `</p>
              <p class="fs-16 mt-3"> <i class="fas fa-clock"></i> Open until ` + store.endTime + ` </p>
              <p class="fs-16 mt-3"> <i class="fas fa-directions"></i> ` + store.distance + ` KM away</p>
              <div class="icon-sec mt-4">
                <ul class="d-flex">
                  <li class="mr-4"><a href="tel:`+ store.maskingNum + `" class="d-flex"> <i class="fas fa-phone-alt"></i> Call</a></li>
                  <li class="mr-4"><a href="/bookademo?cid=in:other:9o8v9h" class="d-flex"> <i class="fas fa-desktop"></i> Book a<br>Demo</a>
                  </li>
                  <li><a href="/store/`+ store.formattedaddress + `" class="d-flex"> <i class="fas fa-store"></i> View Store<br>Details</a></li>
                </ul>
              </div>
            </div>
          </li>`;
        //$('#nearestStoresHolder').append($(storeHtml));
        $('#nearestStoresHolder').slick('slickAdd', $(storeHtml));


      }
    }
  }

  ngOnInit(): void {
    if ($(window).width() <= 767) {
      this.isDesktop = false;
    }



    this.apiService.getLocationByIp().then((result) => {
      this.currentCity = result.city;
    });


  }

  startSearch(ev: any) {
    this.locationflag = false;

    if (true) {
      this.searchPlaces();
    } else {
      this.places = [];
    }
    ev.prop

    const searchString = $('#address').val();

  }

  setAddressAreaBack() {
    $('#address').select();
    $('#addressHolder').css('z-index', '998');
  }

  setAddressAreaFront() {
    $('#addressHolder').css('z-index', '1000');
  }

  searchPlaces() {
    //$('#address').select();
    $('#address').focus();
    /*this.setAddressAreaBack();*/
    this.apiService.getPlaces($('#address').val()).then(result => {
      if (result.status === 'SUCCESS') {
        this.places = result.results;
        if (this.places && this.places.length > 4) {
          this.places.length = 4;
        }
      }
    });
  }

  performAction(ev: any) {
    console.log(ev);
  }

  clearSearch() {
    this.places = [];
  }

  selectPlace(place: any) {
    console.log(place);
    this.selectedPlace = place.description;
    console.log(this.selectedPlace);

    let storeRequest = new SimpleStoresRequest();
    storeRequest.category = 2;
    storeRequest.recNum = 2;
    storeRequest.storeStartNum = 0;
    storeRequest.placeId = place.place_id;

    this.apiService.getStoresBasedOnLocation(storeRequest)
      .then((result) => {
        this.nearestStores = [];
        this.stores = [];
        this.nearestStores = result.listWithinRadius;
        this.stores = this.nearestStores;

        for (let store of this.nearestStores) {
          this.apiService.getStoreByFormattedAddress(store.formattedaddress).then((response) => {
            //console.log(response);
            if (response !== undefined) {


              let storeDetails = response;
              let dealer = storeDetails.dealer;
              if (dealer && dealer.dealerreviews && dealer.dealerreviews.length > 0) {
                console.log("dealer review" + dealer.dealerreviews[0].averageRating);

                store.averageRating = dealer.dealerreviews[0].averageRating;

                console.log(store);
              }
            }
          });


        }

        this.storesPlaceholder?.refreshStores(this.stores);
        //this.subscribeToStores();
        //this.slickDirections();
        console.log(this.nearestStores);
        this.setAddressAreaFront();

      });
  }

  saveLead() {
    this.formsubmited = true;

    if (this.leadCaptureForm.invalid) {

      return;
    }
    this.apiService.saveLead(this.source.sourceId, this.leadCaptureForm.controls.name.value,
      this.leadCaptureForm.controls.email.value,
      this.leadCaptureForm.controls.mobileNo.value,
      this.leadCaptureForm.controls.pincode.value,
      this.leadCaptureForm.controls.bookADemo.value ? 'Y' : 'N').then((resp) => {

        $('#statusPlaceHolder').html(this.leadCreationMessage);
        $.fancybox.open($('#popupthanks'), {
          touch: false
        });
        if (resp.status === 'FAILED') {
          this.leadCreationSubMessage = resp.desc;
          $(".fancybox-content").removeAttr("id", "lead_save_alert_box");
        } else if (resp.status === 'SUCCESS') {
          this.leadCreationMessage = 'Thank You. We have received your request.';
          this.leadCreationSubMessage = 'You will receive a call back soon.';
          $(".fancybox-content").attr("id", "lead_save_alert_box");
          let dataLayer = (<any>window)['dataLayer'];
          dataLayer.push(this.source.url + " landing page");
          dataLayer.push(resp);
        }

        this.leadCaptureForm.reset();
        this.formsubmited = false;
      });
  }

  decoratePage() {
    var slideIndex2 = 1,
      sliding = false;

    $('#fullPage').fullpage({
      scrollingSpeed: 1000,
      css3: true,
      controlArrows: false,
      scrollOverflow: true,

      //to enableing horizontal scrolling in full page plugin
      normalScrollElements: '#tb',



      onLeave: (index: any, nextIndex: any, direction: any) => {
        console.log("scroll event");
        console.log(nextIndex);
        if (nextIndex > 1 && this.isDesktop == false) {
          this.offsetFlag = false;
        }
        else {
          this.offsetFlag = true;
        }

      }

    });



    //$("#btnForm").fancybox();

    /* $('.spotlight').slick({
      dots: false,
      infinite: true,
      speed: 300,
      fade: true,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 3000
    }); */

    $('.yoga-main-slider > ul').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      fade: false,
      asNavFor: '.slider-nav ul'
    });
    $('.slider-nav > ul').slick({
      slidesToShow: 6,
      slidesToScroll: 1,
      asNavFor: '.yoga-main-slider ul',
      dots: true,
      focusOnSelect: true,

    });



    $('.offers-main-slider').slick({
      slidesToScroll: 1,
      arrows: false,
      dots: true,
      autoplay: false,
      autoplaySpeed: 3000,


    });

    //this.slickDirections();

    $(".get-a-call").on('click', function () {
      $.fn.fullpage.moveTo(2);
    })
    $(".locatestorebt").on('click', function () {
      $.fn.fullpage.moveTo(3);
    })

  }


  /* slickDirections() {
    $('.direction-slider > ul').slick({
      slidesToScroll: 1,
      arrows: true,
      dots: false,
      vertical: true,
      verticalSwiping: true,
    });
  } */

  ngAfterViewInit() {
    console.log('asd');
  }

  async checkLocationSetting() {
    this.geolocation$.pipe(take(1)).subscribe(position => {
      this.currentLocation.next(new Location(position.coords.latitude, position.coords.longitude));

      this.apiService.getLocationByIp().then((location: any) => {

        this.city = location.city;

      });
    }, (error) => {

      this.apiService.getLocationByIp().then((location: any) => {
        this.currentLocation.next(new Location(location.lat, location.lng));
        this.city = location.city;
        this.locationstatus = true;

        //alert("plz turn on location")

      });
    });

  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  setproduct(product: any) {
    this.selectedproduct = product;
  }


  @HostListener('window:scroll', [])
  onScroll($event: any) {
    console.log("window.scrollY");
  }
  numericOnly(event: KeyboardEvent): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'Control' || event.key === 'Shift' || event.key === 'Alt' || event.key === 'Tab') {
      result = true;
    }
    //alert(event.key);
    if (!result) {
      /* event.preventDefault();
      event.stopPropagation(); */
      return false;
    }
    return true;
  }


  textOnly(event: any): boolean {
    let patt = /^([a-zA-Z])$/;
    let result = patt.test(event.key);
    return result;
  }


}

