import { Component, Input, OnInit, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';
import { threadId } from 'worker_threads';
import { ApiService } from '../../services/api.service';
import { Dealerreview } from '../../models/dealer';

declare var $: any;

@Component({
  selector: 'app-landing-page-addresses',
  templateUrl: './landing-page-addresses.component.html',
  styleUrls: ['./landing-page-addresses.component.css']
})
export class LandingPageAddressesComponent implements OnInit, AfterViewInit, OnChanges {

  @Input("stores") stores?: any[];
  @Input("sourceId") sourceId?: any[];
  localStores: any[] = [];
  firstTime = true;
  show: boolean = true;


  storename: string = "";
  storeaddress: string = "";
  storecity: string = "";
  storestate: string = "";
  storeendTime: string = "";
  storedistance: string = "";







  storesRef?: any;
  constructor(private apiService: ApiService) {

  }

  ngOnInit(): void {
    console.log("ads");
    this.localStores = JSON.parse(JSON.stringify(this.stores));
    for (let store of this.localStores) {
      this.storedistance = store.distance;

      this.apiService.getStoreByFormattedAddress(store.formattedaddress).then((response) => {
        //console.log(response);
        if (response !== undefined) {


          let storeDetails = response;
          let dealer = storeDetails.dealer;
          if (dealer && dealer.dealerreviews && dealer.dealerreviews.length > 0) {
            console.log("dealer review" + dealer.dealerreviews[0].averageRating);

            store.averageRating = dealer.dealerreviews[0].averageRating;

            console.log(store);
          }
        }
      });


    }

  }

  refreshStores(stores: any[]) {
    //$('.direction-slider > ul').slick('unslick');
    //this.stores = stores;
    //let storesRef = $('.direction-slider > ul').slick('unslick');
    //this.ngAfterViewInit();

  }

  ngAfterViewInit() {

    if (this.firstTime) {
      let storesRef = $('.direction-slider > ul').slick({
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        /* vertical: true,
        verticalSwiping: true, */
      });
      this.firstTime = false;
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("stores check");
    for (let property in changes) {
      if (property === 'stores' && !this.firstTime) {
        let index = 0
        for (let store of JSON.parse(JSON.stringify(this.stores))) {
          let tempstore = this.localStores[index];
          tempstore.name = store.name;
          tempstore.address = store.address;
          tempstore.city = store.city;
          tempstore.state = store.state;
          tempstore.endTime = store.endTime;
          tempstore.distance = store.distance;
          tempstore.maskingNum = store.maskingNum;
          tempstore.formattedaddress = store.formattedaddress;



          this.apiService.getStoreByFormattedAddress(store.formattedaddress).then((response) => {
            //console.log(response);
            if (response !== undefined) {


              let storeDetails = response;
              let dealer = storeDetails.dealer;
              if (dealer && dealer.dealerreviews && dealer.dealerreviews.length > 0) {
                console.log("dealer review" + dealer.dealerreviews[0]?.averageRating);

                tempstore.averageRating = dealer.dealerreviews[0]?.averageRating;


                console.log(store);
                this.localStores[index] = tempstore;
              }
            }
          });





          index++;



        }



        // $('.direction-slider > ul').not('.slick-initialized').slick({
        //   Infinite:false,
        //   slidesToScroll: 1,
        //   arrows: true,
        //   dots: false,
        //   slidesToShow:1,
        // });
        //$('.direction-slider > ul').slick('refresh');
        //$('.direction-slider > ul').not('.slick-initialized').slick()
        //$('.direction-slider > ul').slick();


      }
    }
  }

}
