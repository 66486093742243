export class Store {
  id!: number;
  storeID!: number;
  storeId !: number;
  name!: string;
  postal!: string;
  address!: string;
  city!: string;
  email!: string;
  landline!: string;
  lat!: string;
  lng!: string;
  phone!: string;
  sellertype!: string;
  state!: string;
  status!: string;
  category!: string;
  distance!: string;
  premium!: string;
  badge!: string;
  statusname!: string;
  numofupdatedstore!: string;
  formattedaddress!: string;
  maskingNum!: string;
  masking_number!: string;
  serviceCenter!: boolean;
  bookademo!: boolean;
  tmpMessage!: string;
  tmpMessageHeader!: string;
  premiumStore!: boolean;
  gmapLink!: string;
  endTime!: string;
  buyOnlineLink?: string;
  phoneNumber!:string;
  formattedAddress!:number;
}

export class StoresResponse {
  status?: string;
  listWithinRadius?: Store[];
  listBeyondRadius?: Store[];
  storeNumWithinRadius: number = 0;
  storeNumBeyondRadius: number = 0;
  stores?: Store[];
  totalRecordCount: number = 0;
  adminbannerimages!: string[]
}

export class StoresRequest {
  category: number = 2;
  city?: string;
  lat?: number;
  lng?: number;
  recNum: number = 25;
  placeId?: string;
  search: string = "";
  storeStartNum?: number;


  constructor(lt: number, ln: number, cat: number, recNum: number, storeStartNum?: number) {
    this.lat = lt;
    this.lng = ln;
    this.category = cat;
    this.recNum = recNum;
    this.storeStartNum = storeStartNum;
  }

}

export class SimpleStoresRequest {
  category: number = 2;
  city?: string;
  lat?: number;
  lng?: number;
  recNum: number = 25;
  placeId?: string;
  search: string = "";
  storeStartNum?: number;



}