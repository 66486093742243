import { AfterViewInit, Component, OnInit } from '@angular/core';
var config : any;
declare var $ : any;
@Component({
  selector: 'app-pc-pal-widget',
  templateUrl: './pc-pal-widget.component.html',
  styleUrls: ['./pc-pal-widget.component.css']
})
export class PcPalWidgetComponent implements OnInit,AfterViewInit {

  constructor() { }

  ngOnInit(): void {
    config = document.getElementById('lenovoPcpal');
    config.appCode = 'LqZALY67';

  }



  ngAfterViewInit(): void {
 
  $(document).ready(function() {
    
    let header1 = (<HTMLElement>document.querySelector('#header1'))
  console.log(header1.offsetHeight,"height");
    
    let footer1 = (<HTMLElement>document.querySelector('#footer1'))
  console.log(footer1.offsetHeight,"height");
  
    let doc = window.outerHeight
  console.log(doc,"doc height");
    
   let widgetHeight = doc - header1.offsetHeight - footer1.offsetHeight;
    $(".lenovo-widget-global").css("height",widgetHeight);
    $(".lenovo-widget-global").css("margin-top",header1.offsetHeight);
    $(".lenovo-widget-global").css("min-height",500);
  });

  }

  // ngAfterViewInit(): void {
 
  // $(document).ready(function() {
    
  //   let header1 = (<HTMLElement>document.querySelector('#header1'))
  // console.log(header1.offsetHeight,"height");
    
  //   let footer1 = (<HTMLElement>document.querySelector('#footer1'))
  // console.log(footer1.offsetHeight,"height");
  
  //   let doc = window.outerHeight
  // console.log(doc,"doc height");
    
  //  let widgetHeight = doc - header1.offsetHeight - footer1.offsetHeight;
  //   $(".lenovo-widget-global").css("height",widgetHeight);
  //   $(".lenovo-widget-global").css("margin-top",header1.offsetHeight);
  //   $(".lenovo-widget-global").css("min-height",500);
  // });

  // }

}
