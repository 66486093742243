import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { ApacService } from '../../services/apac.service';
import { AppComponent } from '../../app.component';
import { GtagService } from '../../services/gtag.service';

declare var $: any;

@Component({
    selector: 'app-store-promotions',
    templateUrl: './store-promotions.component.html',
    styleUrls: ['./store-promotions.component.css'],
})
export class StorePromotionsComponent implements OnInit {
    slug = new BehaviorSubject<String>('');
    promotionDetails: any = {};

    name: any;
    email: any;
    phone: any;
    pincode: any;
    consent = false;
    mandate = false;
    deviceMode = 'desktop';
    country = new BehaviorSubject<String>('');
    language = new BehaviorSubject<String>('');
    responseMessage = '';
    @ViewChild('promomodal')
    promomodal!: ElementRef;
    storeDirections: any;
    storeExternalLink: any;

    getaCallForm = this.fb.group({
        name: ['', []],
        email: [
            '',
            [
                Validators.required,
                Validators.pattern(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ),
            ],
        ],
        mobile: ['', [Validators.required]],
        mandate: ['', [Validators.required]],
        consent: ['', []],
    });

    constructor(
        private activatedRoute: ActivatedRoute,
        private apacService: ApacService,
        private fb: FormBuilder,
        public appComp: AppComponent,
        private router: Router,
        public translate: TranslateService,
        private gtagService: GtagService
    ) {
        if (window.screen.width <= 400) {
            this.deviceMode = 'mobile';
        }
        this.activatedRoute.params.subscribe((params) => {
            if (params['slug'] && params['con'] && params['lng']) {
                this.slug.next(params['slug']);
                this.country.next(params['con']);
                this.language.next(params['lng']);
                this.translate.setDefaultLang(params['lng']);
                this.translate.use(params['lng']);

            }
        });

        this.slug.subscribe((val) => {
            this.getPromotionsInfo();
        });

        appComp.getContent();
    }

    ngOnInit(): void {
        // this.gtagService.addscripTag("AW-10912377495")
    }

    ngOnDestroy(): void {
        this.gtagService.deleteScript();
    }

    getPromotionsInfo() {
        this.apacService
            .getPromotionsInfo(
                this.country.getValue(),
                this.language.getValue(),
                'store',
                'promo',
                this.slug.getValue()
            )
            .subscribe(
                (resp) => {
                    this.promotionDetails = resp;

                    this.gtagService.addscripTag(resp.promotion_metadata.measurement_id)
                },
                (err) => {
                    this.router.navigate(['']);
                }
            );
    }

    hasPrivacyPolicy(json: any): boolean {
        json = this.appComp.tenantContent;
        if (!json)
            return false;

        if (!json.footer) {
            return false;
        }
        if (!json.footer.links) {
            return false;
        }
        for (let i = 0; i < json.footer.links.length; i++) {
            if (json.footer.links[i].name === 'Privacy policy') {
                return true;
            }
        }
        return false;
    }

    getPrivacyPolicy(json: any): any {
        if (!json.footer) {
            return null;
        }
        if (!json.footer.links) {
            return null;
        }
        for (let i = 0; i < json.footer.links.length; i++) {
            if (json.footer.links[i].name === 'Privacy policy') {
                return json.footer.links[i].url;
            }
        }
        return null;
    }

    get f() {
        return this.getaCallForm.controls;
    }

    checkDataValid() {
        return this.f.mobile.valid && this.f.email.valid && (this.mandate == true);
    }

    submitLeadForm() {
        let data = this.getaCallForm.value;
        let leadDetails = {
            name: data.name,
            email: data.email,
            mobile: data.mobile,
            storeId: this.promotionDetails.store_id,
            sourceName: 'Store Campaign',
            mandate: true,
            consent: this.consent
        };

        console.log(leadDetails);

        this.apacService
            .submitGetacallForm(
                leadDetails,
                this.appComp.countryName,
                this.appComp.localeId.split('-')[0]
            )
            .subscribe((resp: any) => {
                if (resp.status === 'SUCCESS') {
                    this.responseMessage =
                        this.promotionDetails.promotion_metadata.success_message;
                    this.storeDirections =
                        this.promotionDetails.gmap_link;
                    this.storeExternalLink =
                        this.promotionDetails.promotion_metadata.external_site_link;
                    this.promomodal.nativeElement.style.display = 'flex';
                    this.getaCallForm.reset();
                }
            });
    }

    numberOnly(event: { which: any; keyCode: any }): boolean {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        if (!/^[0-9]*$/.test(charCode)) {
            return false;
        }
        return true;
    }
    numberMobile(e: any) {
        e.target.value = e.target.value.replace(/[^\d]/g, '');
        return false;
    }

    dismissMessage() {
        this.promomodal.nativeElement.style.display = 'none';
        this.consent = false;
        this.mandate = false;
    }
}
