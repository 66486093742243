<div class="row mx-auto">
    <div class="col-10 my-2 mx-auto">
        <div class="buyinghelp-container">

            <div class="row justify-content-center">

                <div class="col-md-2 col-6 d-flex align-items-center justify-content-center">
                    <img src="./assets/images/intel-img.webp" width="180" height="auto" alt="" class="img-fluid"
                        loading="lazy">
                </div>
                <div class="col-md-2 col-6 d-flex align-items-center justify-content-center ">
                    <p class="fs-18">Intel® Core™ Processors</p>
                </div>




                <div class="col-md-3 p-4 col-xs-12 d-flex align-items-center justify-content-center">
                    <h3><b style="font-size: 18px;font-weight: 600;color: #737373;">Need buying help?</b></h3>
                </div>
                <div class="col-md-5 col-xs-12">
                    <div class="row action-buttons">
                        <div class="col-12 mb-2">
                            <p class="text-center">
                                Talk to a lenovo product expert <span>@ 1800 4199 732</span>
                            </p>
                        </div>
                        <div class="col-4 p-xs-1 d-flex align-items-center justify-content-end">
                            <a id="storeLocator" href="/" class=" col-xs-12 p-xs-1  fs-14 fs-m-10" target="_blank">Store
                                Locator</a>
                        </div>
                        <div class="col-4  p-xs-1 d-flex align-items-center justify-content-center">
                            <a id="pcbuyingguide" href="http://www3.lenovo.com/in/en/pcbuyingguide/"
                                class=" col-xs-12 p-xs-1  fs-14 fs-m-10" target="_blank">PC
                                Buying Guide</a>
                        </div>
                        <div class="col-4 d-flex p-xs-1 align-items-center justify-content-start">
                            <a id="bookademo" href="/bookademo/?cid=in:other:9o8v9h" target="_blank"
                                class="p-xs-1 col-xs-12  fs-14 fs-m-10">Book A
                                Demo</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>