import { Component, Input, OnInit } from '@angular/core';
import { ApacService } from 'src/app/services/apac.service';
import { AppComponent } from 'src/app/app.component';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { HeaderComponent } from '../header/header.component';
declare var $: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  isDesktop: boolean = true;
  selectedCountry!: string;
  langs !: any[];

  @Input("sellertype") sellertype: boolean = false;

  constructor(
    public appComp: AppComponent,
    private utility: UtilitiesService,
    private apac: ApacService) {
    if ($(window).width() <= 767) {
      this.isDesktop = false;
    }
  }

  ngOnInit(): void {
    this.selectedCountry = this.appComp.countryName;
    if (this.appComp.languages.length > 0) {
      this.langs = this.appComp.languages;
    }

    // this.apac.countryNameSubject.subscribe(con =>{
    //   if(con && con.length >0){
    //     this.appComp.countryName = con;
    //     this.selectedCountry = this.appComp.countryName; 
    //   }
    // })
  }

  getYear() {
    return new Date().getFullYear();
  }

  getCountyAndLangFor(eve: any) {
    this.appComp.isLoad = true;
    this.appComp.countrySub.next(this.selectedCountry);
    this.appComp.countryName = this.selectedCountry;
    this.utility.getCountyAndLangFor(this.appComp, this.selectedCountry)
  }
}

