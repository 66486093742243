import { AppSettingsResponse } from '../models/settings';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdminBannerResponse } from '../models/banner';
import { CapcthaKeyResponse } from '../models/captcha';
import { StoreDetailsResponse } from '../models/dealer';
import { Location } from '../models/location';
import { SearchResult } from '../models/search';
import { StoresResponse, StoresRequest } from '../models/store';
import { SurveyResponse, SurveySubmissionResponse } from '../models/survey';

@Injectable({
  providedIn: 'root'
})
export class ApiService {


  constructor(private http: HttpClient) { }

  private getHeader() {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      }),
    };
    return httpOptions;
  }

  private getHeaderWithQueryParams(httpParams: HttpParams) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      }),
      params: httpParams
    };
    return httpOptions;
  }

  getLocationByIp(): Promise<Location> {
    const httpParam = new HttpParams().set('key', 'N7pS0QyVGJ09X0h');
    return this.http.get<any>('https://pro.ip-api.com/json', this.getHeaderWithQueryParams(httpParam))
      .toPromise().then<any>((position) => {
        return new Location(position.lat, position.lon, position.city);
      }).catch((error) => {
        console.log(error);
      });
  }

  getStoresBasedOnLocation(storesRequest: StoresRequest): Promise<StoresResponse> {
    return this.http.post<StoresResponse>('/rest/service/getStoreWithinRadius', storesRequest, this.getHeader())
      .toPromise()
      .then<StoresResponse>();
  }

  getStoresBeyondradius(storesRequest: StoresRequest): Promise<StoresResponse> {
    return this.http.post<StoresResponse>('/rest/service/getStoreBeyondRadius', storesRequest, this.getHeader())
      .toPromise()
      .then<StoresResponse>();
  }

  getStoresForLocation(storesRequest: StoresRequest): Promise<StoresResponse> {
    return this.http.post<StoresResponse>('/rest/service/getStoreWithinRadius', storesRequest, this.getHeader())
      .toPromise()
      .then<StoresResponse>();
  }

  getServiceCentersBasedOnLocation(storesRequest: StoresRequest): Promise<StoresResponse> {
    return this.http.post<StoresResponse>('/rest/service/getServiceCenterWithinRadius', storesRequest, this.getHeader())
      .toPromise()
      .then<StoresResponse>();
  }

  getServiceCentersBeyondradius(storesRequest: StoresRequest): Promise<StoresResponse> {
    return this.http.post<StoresResponse>('/rest/service/getServiceCenterBeyondRadius', storesRequest, this.getHeader())
      .toPromise()
      .then<StoresResponse>();
  }

  getBannerImages(forLandingPage: boolean): Promise<AdminBannerResponse> {
    const httpParam = new HttpParams().set('for_landing_page', String(forLandingPage));
    return this.http.get<AdminBannerResponse>('/rest/service/adminbanners', this.getHeaderWithQueryParams(httpParam))
      .toPromise()
      .then<AdminBannerResponse>();

  }

  getStoreByFormattedAddress(formattedAddress: string): Promise<StoreDetailsResponse> {
    return this.http.get('/partner/rest/service/getStoreDetailsByFormattedAddress?formattedAddress=' + formattedAddress, this.getHeader())
      .toPromise()
      .then();
  }

  submitGetacallForm(leadDetails: any) {
    return this.http.post('/partner/rest/service/partnerlandingpageform', leadDetails, this.getHeader())
  }

  getSurveyRating(sourceId: string, rating: string): Promise<SurveyResponse> {
    const httpParam = new HttpParams().set('sourceId', sourceId).set('rating', rating);
    return this.http.get<SurveyResponse>('/rest/service/getRatingReason', this.getHeaderWithQueryParams(httpParam))
      .toPromise()
      .then();
  }

  getAppSettings(): Promise<AppSettingsResponse> {
    return this.http.get<AppSettingsResponse>('/rest/service/settings', this.getHeader())
      .toPromise()
      .then();
  }

  submitSurvey(data: any): Promise<SurveySubmissionResponse> {
    return this.http.post<SurveySubmissionResponse>('/rest/service/addsurveyreview', data, this.getHeader())
      .toPromise()
      .then();
  }

  getPlaces(searchString: string): Promise<SearchResult> {
    const httpParam = new HttpParams().set('kw', searchString);
    return this.http.get<SearchResult>('/rest/service/auto-search', this.getHeaderWithQueryParams(httpParam))
      .toPromise()
      .then();
  }


  geocode(lat: any, lng: any): Promise<any> {
    const httpParam = new HttpParams().set('lat', lat).set('lng', lng);
    return this.http.get('/rest/service/geocode', this.getHeaderWithQueryParams(httpParam))
      .toPromise()
      .then();
  }

  submitStudentOffer(offerCode: string): Promise<any> {
    return this.http.get('rest/service/validateStudentofferCode?offerCode=' + offerCode, this.getHeader())
      .toPromise()
      .then();
  }

  getTACandFAQ(id: string) {
    const campaignTypeId = 1;
    return this.http.get(`/rest/service/getTACandFAQ?campaignTypeId=${campaignTypeId}`);
  }

  getStoreMarkupService(storeId: string): Promise<any> {
    const httpParam = new HttpParams().set('storeId', String(storeId));
    return this.http.get('rest/service/getStoreMarkupService', this.getHeaderWithQueryParams(httpParam))
      .toPromise()
      .then();;
  }

  getLandingPageDetails(sourcePath: string): Promise<any> {
    return this.http.get('rest/service/public/sources/' + sourcePath, this.getHeader())
      .toPromise()
      .then();
  }

  getStoreDetails(lat: number, lng: number): Promise<any> {
    return this.http.get('rest/service/getStoreDetails', this.getHeader())
      .toPromise()
      .then();
  }


  saveLead(sourceId: string, name: string, email: string, mobileNo: string, pincode: string, bookADemo: string): Promise<any> {
    const httpParam = new HttpParams().set('id', sourceId).set('name', name).set('email', email).set('phone', mobileNo)
      .set('pincode', pincode)
      .set('bookADemo', bookADemo);

    return this.http.get('rest/service/saveBTCDetails', this.getHeaderWithQueryParams(httpParam))
      .toPromise()
      .then();
  }

}
