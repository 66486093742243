import { Component, OnInit } from '@angular/core';
declare var $: any;


@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css']
})
export class PdfViewerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    function theKingIsBlackPageCallback(n:any) {
      return {
        type: 'image',
        src: './assets/books/image/theKingIsBlack/'+(n+1)+'.jpg',
        interactive: false
      };
    }
  
    $('#pdf-container').FlipBook({
      //pageCallback: this.theKingIsBlackPageCallback,
      pdf: './assets/books/pdf/Orginal_Update_2-Dec-2021(2).pdf',
      template: {
        html: './assets/templates/default-book-view.html',
        links: [
          {
            rel: 'stylesheet',
            href: './assets/css/font-awesome.min.css'
          }
        ],
        styles: [
          './assets/css/short-white-book-view.css'
        ],
        script: './assets/js/default_book_view.js',
        sounds: {
          startFlip: './node_modules/flip-book/sounds/start-flip.mp3',
          endFlip: './node_modules/flip-book/sounds/end-flip.mp3'
        }
      }
    });
  }

}
