import {
  Component, OnInit, ViewChild,
  ElementRef, AfterViewInit, Renderer2,
  HostListener, Input, Output, OnChanges,
  SimpleChange, SimpleChanges
} from '@angular/core';
import { HtmlParser } from '@angular/compiler';
import { CarouselComponent } from 'angular-responsive-carousel';
import { ApiService } from '../../services/api.service';
declare var $: any;

@Component({
  selector: 'app-apac-offers',
  templateUrl: './apac-offers.component.html',
  styleUrls: ['./apac-offers.component.css'],
  styles: [`
      ::ng-deep .offer-gallery .carousel-arrow-next{
          right: -20px !important;
      }

      ::ng-deep .offer-gallery .carousel-arrow-prev{
          left: -20px !important;
      }
  `]
})
export class ApacOffersComponent implements OnInit, OnChanges {

  @ViewChild("offerGallery") offerGallery!: ElementRef;

  //images: any[] = [];
  bannersLoaded = 0;
  @Input('images') images: any[] = [
    {
      "src": "https://lenovo-apac.s3.ap-south-1.amazonaws.com/offer-banners/4329.jpg"
    },
    {
      "src": "https://lenovo-apac.s3.ap-south-1.amazonaws.com/offer-banners/Legion%205i%20Pro_1284x896_300%20Dpi.png"
    }
  ]

  @Input('height') height: number = 250;
  @Input('width') width: number = 400;
  @ViewChild("innerOffersCaraousel") innerOffersCaraousel?: CarouselComponent
  bannerImages: any[] = [];
  slider: any;

  constructor(private apiService: ApiService, private renderer: Renderer2) { }

  ngOnInit(): void {

    console.log("this.images ", this.images);

    /* this.apiService.getBannerImages(this.forLandingPage).then(result => {
      //this.reInitOffersGallery();
      this.banners = result.adminbannerimages;
      //this.renderer.setStyle(this.offerGallery.nativeElement, 'display', 'block');

      //this.showBanners();

    }); */

    /* $('.offers-carousel').click(() => {
      
    }); */
  }

  showPage(ev: any) {
    if ($(ev.target).hasClass('carousel-arrow')) {
      return;
    }
    const url = this.images[this.getCurrentSlide()].url;
    if (url) {
      window.open(url, '_blank');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    let counter = 0;
    for (let property in changes) {
      if (property === 'images') {
        this.bannerImages = [];
        $('#thumb-list_holder').html('');
        for (let img of this.images) {
          this.bannerImages.push({ path: img.src, alt: img.image_alt_text });
          $('#thumb-list_holder').append(`<li>
          <a href="javascript:" id="offer_thumb_id_`+ (counter++) + `">
              <img src="`+ img.src + `"   width="100%" height="auto" alt="` + img.image_alt_text + `" class="img-fluid">
          </a>
          </li>`);
        }
      }
    }
  }


  getCurrentSlide() {
    return this.innerOffersCaraousel?.slideCounter;
  }

  nextSlide() {
    this.innerOffersCaraousel?.next();
  }

  prevSlide() {
    this.innerOffersCaraousel?.prev();
  }
  //Sample
}

