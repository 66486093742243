
    <app-header id="header"></app-header>
    <div class="lenovo-widget-global" > 
        <lenovo-pcpal id="lenovoPcpal"></lenovo-pcpal> 
    </div> 
    <app-footer id="footer"></app-footer>








