import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharingService {
  private storeDetails:any=[];

  constructor() {
    this.storeDetails = JSON.parse(localStorage.getItem('storeDetails') || '{}');
   }

   getSharedObject() {
    return this.storeDetails;
  }

  setSharedObject(obj: any) {
    this.storeDetails = obj;
    // Save the shared object to local storage
    localStorage.setItem('sharedObject', JSON.stringify(obj));
  }


 async share(text: any, title: any, url: any): Promise<any> {
    const navigator = window.navigator as any;
    return navigator.share({
      text: text,
      title: title,
      url: url
    }).then((resp:any) => {

    });
  
}
}
