<div class="survey-content" style="padding: 20px;background:#fff;">
    <div *ngIf="_for==='desktop'" #surveyBox2 class="card-bx flex-column survey-intro-screen  justify-content-center">
        <div class="d-flex justify-content-center align-items-center justify-content-between">
            <div class="d-flex">
                <span class="col-4 d-flex flex-column">
                    <span class="survey-title">Take Our Survey</span>
                    <img src="./assets/images/survey-img.png" width="165" height="128" alt="" class="img-fluid"
                        loading="lazy">
                </span>
                <span class="col-8 d-flex flex-column">
                    <h4 class="text-center">How do you rate your
                        experience of finding a nearby Lenovo store?

                    </h4>
                    <!-- <p class="sub-txt">Please rate on a scale of
                        1-5 where 5 is very good and 1 is very
                        poor.</p> -->
                    <div class="survey-number-bx survey-content d-flex justify-content-center pt-3">
                        <!-- <img src="images/survey-number-img.png" width="297" height="63" alt="" class="img-fluid"> -->
                        <div class="row">
                            <div class="col-md-2">
                            </div>
                            <div class="col-md-10 d-flex flex-column" style="min-width: 300px;">
                                <!-- <div  class="d-flex rating-top mb-2 nps-scale">
                              <div>1</div>
                              <div>2</div>
                              <div>3</div>
                              <div>4</div>
                              <div>5</div>
                           </div> -->
                                <div class=" radio-bx">

                                    <div class="desktop-rating-wrapper">
                                        <!-- <star-rating #star1 [value]="0"  [totalstars]="5" [checkedcolor]="checkedcolor" 
    [uncheckedcolor]="uncheckedcolor"  [readonly]="false" size="30px" (rate)="initSurvey($event)">
     </star-rating> -->
                                        <app-emoji-scale (rateEvent)="initSurvey($event)" [ratingLength]="currentItem"
                                            [ratingString]="ratingString"></app-emoji-scale>

                                    </div>

                                </div>
                                <!-- <div  class="d-flex rating-top mt-2" style="justify-content: space-evenly;">
                              <div class="d-flex flex-column">
                                  <span>Very</span> 
                                  <span>Poor</span>
                               </div>
                              <div >Poor</div>
                              <div >Average</div>
                              <div >Good</div>
                              <div class="d-flex flex-column">
                                  <span>Very</span> 
                                  <span>Good</span>
                               </div>
                           </div> -->
                            </div>
                        </div>
                    </div>
                </span>
            </div>
        </div>
    </div>

    <div *ngIf="_for==='mobile'" #surveyBox1 class="survey-bg">
        <div class="survey-intro-screen">
            <h4 class="text-center">How do you rate your
                experience of finding a nearby Lenovo store?
            </h4>
            <span>
                <img src="./assets/images/survey-img.png" width="273" height="212" alt="" class="img-fluid"
                    loading="lazy">
            </span>
            <div class="survey-number-bx survey-content d-flex justify-content-center pt-3">
                <div class="row">
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-10 d-flex flex-column" style="min-width: 300px;">
                        <!-- <div  class="d-flex rating-top mb-2 nps-scale">
                      <div>1</div>
                      <div>2</div>
                      <div>3</div>
                      <div>4</div>
                      <div>5</div>
                   </div> -->
                        <div class=" radio-bx">
                            <div class="desktop-rating-wrapper">
                                <!-- <star-rating #star2 [value]="0"  [totalstars]="5" [checkedcolor]="checkedcolor" 
[uncheckedcolor]="uncheckedcolor"  [readonly]="false" size="30px" (rate)="initSurvey($event)">
</star-rating> -->
                                <app-emoji-scale (rateEvent)="initSurvey($event)" [ratingLength]="currentItem"
                                    [ratingString]="ratingString"></app-emoji-scale>

                            </div>

                        </div>
                        <!-- <div  class="d-flex rating-top mt-2" style="justify-content: space-evenly;">
                      <div class="d-flex flex-column">
                          <span>Very</span> 
                          <span>Poor</span>
                       </div>
                      <div >Poor</div>
                      <div >Average</div>
                      <div >Good</div>
                      <div class="d-flex flex-column">
                          <span>Very</span> 
                          <span>Good</span>
                       </div>
                   </div> -->
                        <!-- <p class="sub-txt mt-2">Please rate on a scale of
                    1-5 where 5 is very good and 1 is very
                    poor.</p> -->
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>