export class Location {
  lat: number = 0;
  lng: number = 0;
  city: string = "India";

  constructor(lt: number, ln: number, city?: string) {
    this.lat = lt;
    this.lng = ln;
    if (city) {
      this.city = city;
    }
  }

}