<h2 class="section-title">{{'store-listing.section-title-blogs' | translate}}</h2>
<!-- <carousel  [borderRadius]="20" [cellsToShow]="1" [cellWidth]="552" [width]="552" [height]="280">
        <div class="carousel-cell">
            <img (dblclick)="expression()" class="img-fluid"   src="./assets/images/Book A Demo 1500 x 700.jpg">
        </div>
        <div class="carousel-cell">
            <img (dblclick)="expression1()" class="img-fluid"   src="./assets/images/PC PAL 1500 x 700.jpg">
        </div>
    </carousel> -->
<carousel [arrows]="false" #innerAssistanceCaraousel style="z-index:1;cursor: pointer;" (click)="showPage($event)"
    [borderRadius]="20" [cellsToShow]="1" [cellWidth]="552" [height]="height"
    *ngIf="bannerImages1 && bannerImages1.length>0" [images]="bannerImages1">
    <ng-container *ngFor="let item of bannerImages1">
        <img [src]="item.path" loading="lazy" alt="Lenovo Blogs"></ng-container>
</carousel>