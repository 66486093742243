<!-- header start -->
<header id="header1">
    <div class="container clearfix">
        <a href="javascript:;" (click)="go('home')" class="logo">
            <img src="/assets/images/lenovo-apac.webp" width="" height="" alt="" class="img-fluid" loading="lazy" />
        </a>

        <!-- <div class="nav-link-mob d-lg-block">
            <ul class="clearfix">
                <li>
                    <div
                        class="country-region d-flex align-items-center"
                        style="max-width: 160px; min-width: 160px"
                    >
                        <img [src]="'./assets/flags/4x3/'+appComp.countryName+'.svg'" style="width: 6%; position: fixed; z-index: 1; align-self: center; top: 20px; left: auto; margin-left: 5px;">
                    </div>
                </li>
            </ul>
        </div> -->

        <!-- -->
        <div class="main-nav-bx">
            <a *ngIf="(!this.appComp.countryListShow)" href="javascript:;" (click)="open()"
                class="nav-dot-icon d-lg-none">&nbsp;</a>
            <div class="main-nav clearfix">
                <a href="javascript:;" (click)="close()" class="main-nav-close d-lg-none">&nbsp;</a>
                <ul *ngIf="!this.appComp.countryListShow">
                    <li class="menu-item-1">
                        <a href="javascript:;" (click)="go('stores')">
                            <span class="menu-icon store-icon"></span>
                            <span style="left:3px;" class="menu-txt " id="overflow-inherit">{{
                                "store-listing.header.discover-stores" | translate }}</span>
                        </a>
                    </li>
                    <li class="menu-item-1">
                        <a href="javascript:;" (click)="go('warranty')">
                            <span class="menu-icon warranty-icon"></span>
                            <span class="menu-txt " id="overflow-inherit">{{ "store-listing.header.warranty" | translate
                                }}</span>
                        </a>
                    </li>
                    <li class="menu-item-1">
                        <a href="javascript:;" (click)="go('website')">
                            <span class="menu-icon globe-icon"></span>
                            <span class="menu-txt" id="overflow-inherit">{{ "store-listing.header.official-website" |
                                translate }}</span>
                        </a>
                    </li>
                    <!-- <li class="menu-item-2 d-flex align-items-center justify-content-center">
                        
                        <div class="text-right country-region d-flex align-items-center justify-content-center" >
                        <img [src]="'./assets/flags/4x3/'+appComp.countryName+'.svg'" style="width: 6%; position: relative; left: 32px; z-index: 1; border: 1px solid #d8d8d8;"> 
                            <select name="lng" id="lng" *ngIf="!this.appComp.countryListShow" (change)="selectChange($event)" [(ngModel)]="this.appComp.countryName" class="country-select-bx">
                                <option value="{{value.code}}" *ngFor="let value of countryLists">{{value.name}}</option>
                             </select> 
                        </div>
                    </li> -->
                    <li *ngIf="
                            !this.appComp.countryListShow &&
                            appComp?.tenantContent?.header?.tel_number
                        " class="d-lg-none"
                        style="height: 60px; border-top: 1px solid #8b8b8b; border-bottom: 1px solid #8b8b8b; padding: 4px 0;">
                        <p style="font-size: 0.8em; text-align: center;">{{ "store-listing.header.toll-free" | translate
                            }}</p>
                        <a style="font-size: 17px;" class="d-block" href="tel:{{
                                appComp?.tenantContent?.header?.tel_number
                            }}">
                            <strong>{{
                                appComp?.tenantContent?.header?.tel_number
                                }}
                            </strong></a>
                    </li>
                    <li>
                        <div class="header-icons mr-3">
                            <ng-container *ngFor="
                                    let item of appComp?.tenantContent?.header
                                        ?.social
                                ">
                                <a *ngIf="item.icon" href="{{ item?.link }}" target="_blank" class="social-icon"><img
                                        src="{{ item?.icon }}" width="20" height="20" alt="" class="img-fluid"
                                        loading="lazy" /></a>
                            </ng-container>

                            <!-- <img src="/assets/images/fb_icon.svg" alt="icon" />
                            <img
                                src="/assets/images/linkedin_icon.svg"
                                alt="icon"
                            />
                            <img
                                src="/assets/images/twitter_icon.svg"
                                alt="icon"
                            />
                            <img
                                src="/assets/images/instragram_icon.svg"
                                alt="icon"
                            />
                            <img
                                src="/assets/images/youtube_icon.svg"
                                alt="icon"
                            /> -->
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="ml-auto d-flex align-items-center header-right-container">
            <div class="header-icons">
                <ng-container *ngFor="let item of appComp?.tenantContent?.header?.social">
                    <a *ngIf="item.icon" href="{{ item?.link }}" target="_blank" class="social-icon"><img
                            src="{{ item?.icon }}" width="20" height="20" alt="" class="img-fluid w-100"
                            loading="lazy" />
                    </a>
                </ng-container>
                <!-- <img src="/assets/images/fb_icon.svg" alt="icon" />
                <img src="/assets/images/linkedin_icon.svg" alt="icon" />
                <img src="/assets/images/twitter_icon.svg" alt="icon" />
                <img src="/assets/images/instragram_icon.svg" alt="icon" />
                <img src="/assets/images/youtube_icon.svg" alt="icon" /> -->
            </div>

            <!-- <span
                *ngIf="getTollNumber() && !this.appComp.countryListShow && appComp?.tenantContent?.header?.tel_number"
                class="tollfree-txt d-none d-lg-block grade d-inline-block"
                ><a
                    href="tel:{{ appComp?.tenantContent?.header?.tel_number}}"
                    style="text-decoration: none; color: #fff"
                    >Offer Helpline -<strong>{{ appComp?.tenantContent?.header?.tel_number}}</strong></a
                ></span
            > -->
            <span *ngIf="
                    !this.appComp.countryListShow &&
                    appComp?.tenantContent?.header?.tel_number
                " style="cursor: pointer;" class="tollfree-txt d-none d-lg-block grade"><a
                    href="tel:{{ appComp?.tenantContent?.header?.tel_number }}"
                    style="text-decoration: none; color: #fff"><span>{{ "store-listing.header.toll-free" | translate }}
                    </span>
                    <strong>{{
                        appComp?.tenantContent?.header?.tel_number
                        }}</strong></a></span>

            <!--  <select class="header-language-selection">
                <option value="en">EN</option>
                <option value="en">EN</option>
                <option value="en">EN</option>
            </select> -->


            <select class="header-language-selection d-flex align-items-center justify-content-center"
                *ngIf="(!this.appComp.countryListShow) && (appComp.lngs.length > 0)" name="lng" id="lng1"
                [(ngModel)]="this.appComp.language" (change)="selectLangsChange($event)">
                <option [value]="value.code" *ngFor="let value of appComp.lngs">
                    {{ value.code | uppercase }}
                </option>
            </select>
            <!-- {{this.appComp.language + " language"}} -->
            <div class="header-country-selection-container" *ngIf="(!this.appComp.countryListShow)"
                style="cursor: pointer;">
                <img src="/assets/images/globe.svg" alt="" width="24" (click)="showCountries()" loading="lazy" />

                <select *ngIf="countryLists.length > 0" name="lng" id="lng" (change)="selectChange($event)"
                    [(ngModel)]="this.appComp.countryName"
                    class="header-country-selection d-flex align-items-center justify-content-center">
                    <option value="{{ value.code }}" *ngFor="let value of countryLists">
                        {{ value.name }}
                    </option>
                </select>
            </div>
            <!-- <select class="header-language-selection">
                <option value="en">EN</option>
                <option value="en">EN</option>
                <option value="en">EN</option>
            </select> -->

            <!-- <select class="language-select-bx d-flex align-items-center justify-content-center d-lg-none" *ngIf="!this.appComp.countryListShow"   name="lng" id="lng"  [(ngModel)]='this.appComp.language' (change)="selectLangsChange($event)">
                <option value="{{value.code}}" *ngFor="let value of lngs">{{value.name}} </option>
             </select> -->
        </div>
    </div>
</header>
<!-- header end -->