<div class="promotions-container">
    <div *ngIf="promotionDetails && promotionDetails.promotion_metadata && (promotionDetails.promotion_metadata.banner_image_desktop || promotionDetails.promotion_metadata.banner_image_mobile)"
        [ngStyle]="(promotionDetails && promotionDetails.promotion_metadata && (promotionDetails.promotion_metadata.banner_image_desktop || promotionDetails.promotion_metadata.banner_image_mobile))?{'background-image': 'url(' + (deviceMode==='desktop'?promotionDetails.promotion_metadata.banner_image_desktop:promotionDetails.promotion_metadata.banner_image_mobile) + ')'}:{}"
        class="promotions-banner">

    </div>

    <div class="row promotions-box">
        <form [formGroup]="getaCallForm" (ngSubmit)="submitLeadForm()">
            <div class="col-12">
                <h3 *ngIf="promotionDetails && promotionDetails.promotion_name">{{promotionDetails.promotion_name}}</h3>
            </div>
            <div class="col-md-12 col-sm-12 my-2">
                <input id="name" formControlName="name" class="w-100 inputbox"
                    [placeholder]="'store-listing.store-landing.blue-shop.form.label-name' | translate">
            </div>
            <div class="col-md-12 col-sm-12 my-2">
                <input id="phone" formControlName="mobile" class="w-100 inputbox"
                    [placeholder]="'store-listing.store-landing.blue-shop.form.label-mobile' | translate"
                    (keypress)="numberOnly($event)" (keyup)="numberMobile($event)">
                <span *ngIf="f.mobile.touched && f.mobile.errors" class="promoerror">
                    Please share a valid mobile number
                </span>
            </div>
            <div class="col-md-12 col-sm-12 my-2">
                <input id="email" formControlName="email" class="w-100 inputbox"
                    [placeholder]="'store-listing.store-landing.blue-shop.form.label-email' | translate">
                <span *ngIf="f.email.touched && f.email.errors" class="promoerror">
                    Please share a valid email id
                </span>
            </div>

            <div class="col-12 d-flex align-items-center">
                <span class="mr-2">*</span><em class="footnote">{{'store-listing.store-landing.blue-shop.note' |
                    translate}}</em>
            </div>

            <div class="col-12 d-flex my-3">
                <input type="checkbox" id="mandated" [(ngModel)]="mandate" [ngModelOptions]="{standalone:true}"
                    name="vehicle1" value="Bike" style="margin-right: 10px;" />
                <label for="mandated" formControlName="mandate" style="font-size: 0.75rem; ">
                    I understand Lenovo Thailand will pass my contact information to a Lenovo Authorized Reseller to
                    contact me for this promotional offer <div style="color:red"> (*Required).</div> </label>

            </div>

            <div class="col-12 d-flex my-3">
                <input type="checkbox" id="subscribe" [(ngModel)]="consent" [ngModelOptions]="{standalone:true}"
                    name="vehicle1" value="Bike" style="margin-right: 10px;" />
                <label for="subscribe" formControlName="consent" style="font-size: 0.75rem; ">
                    I want to receive marketing communications from Lenovo in accordance with Lenovo’s
                    <ng-container *ngIf="hasPrivacyPolicy(appComp.tenantContent)"> <a
                            [href]="'store-listing.store-landing.privacy-policy' | translate" target="_blank">Lenovo
                            Privacy
                            Policy</a></ng-container>
                    You may unsubscribe anytime using the link in each email communication.</label>
            </div>
            <div class="col-12 d-flex">
                <button class="promotions-submit" type="submit"
                    [disabled]="!checkDataValid()">{{'store-listing.store-landing.blue-shop.submit' |
                    translate}}</button>
            </div>
            <div #promomodal class="promo-modal w-100" style="display: none;">
                <h4 class="text-center mb">{{responseMessage}}</h4>
                <div class="promo-links-holder mt-5">
                    <a _ngcontent-bwv-c104="" target="_blank" class="directions p-0" *ngIf="promotionDetails"
                        [href]="storeDirections">Visit
                        {{promotionDetails.promotion_metadata.title}} Store</a>
                    <a _ngcontent-bwv-c104="" class="view-store" *ngIf="promotionDetails" [href]="storeExternalLink"
                        target="_blank">Visit
                        {{promotionDetails.promotion_metadata.title}} Online</a>
                    <img class="promo-dismiss" src="/assets/images/close-icon-white.png" (click)="dismissMessage()"
                        loading="lazy">
                </div>

            </div>
        </form>


    </div>
</div>