import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Logs } from 'selenium-webdriver';
declare var $ : any;

@Component({
  selector: 'app-emoji-scale',
  templateUrl: './emoji-scale.component.html',
  styleUrls: ['./emoji-scale.component.css']
})
export class EmojiScaleComponent implements OnInit {
  @Output() rateEvent = new EventEmitter<string>();
  @Input() ratingLength!:number;
  @Input() ratingString!:String[];
  ratingStringTen : number[] = [1,1,1,1,2,2,2,3,3,5,5];
  myTag:any
  winw:any
  
  constructor(private el: ElementRef) { }

  ngOnInit(): void {
     this.winw = $(window);
    
  }
  filterValue(counter:number){
    if(counter == 4){
      counter--;
    }
    return this.ratingStringTen.filter(x => x == counter).length;
  }
  

  selectedRating(value:any,eve:any){

    let allEmoji  = this.el.nativeElement.querySelectorAll(".emoji_bg").forEach((emoji:any)=>{
    emoji.classList.remove('selected');
    });

    this.myTag = eve.target;

    if(!this.myTag.classList.contains('selected'))
    {
    this.myTag.classList.add('selected'); 
    }
    this.rateEvent.emit(value)
  }



}
