<!-- footer start -->
<footer id="footer1">
    <!-- <div class="row no-gutters clearfix">
        
        <div class="col-lg-8 d-flex align-items-center">
            <div class="copyright-txt">
                <p>© Lenovo {{getYear()}} . All rights reserved. Lenovo and the Lenovo logo are trademarks of Lenovo in the United States, other countries or both. All Images shown are for Illustrative purposes only,and might not resemble the actual product. Ultrabook, Celeron, Celeron Inside, Core Inside, Intel, Intel Logo, Intel Atom, Intel Atom Inside, Intel Core, Intel Inside, Intel Inside Logo, Intel vPro, Itanium, Itanium Inside, Pentium, Pentium Inside, vPro Inside, Xeon, Xeon Phi, and Xeon Inside are trademarks of Intel Corporation or its subsidiaries in the U.S. and/or other countries.</p>
            </div>
        </div>
    </div> -->

    <div class="fotterct1">
        <div class="w-100 px-5">
            <div class="row">
                <div class="col-md-8">
                    <p class="m-0 fs-10 footer-content">
                        <!-- © Lenovo 2022 . All rights reserved. Lenovo and the
                        Lenovo logo are trademarks of Lenovo in the United
                        States, other countries or both. All Images shown are
                        for Illustrative purposes only,and might not resemble
                        the actual product. Ultrabook, Celeron, Celeron Inside,
                        Core Inside, Intel, Intel Logo, Intel Atom, Intel Atom
                        Inside, Intel Core, Intel Inside, Intel Inside Logo,
                        Intel vPro, Itanium, Itanium Inside, Pentium, Pentium
                        Inside, vPro Inside, Xeon, Xeon Phi, and Xeon Inside are
                        trademarks of Intel Corporation or its subsidiaries in
                        the U.S. and/or other countries. -->

                        {{ appComp?.tenantContent?.footer?.content }}
                    </p>
                </div>
                <div class="col-md-4">
                    <!-- <div class="text-right country-region" >
                         <label>Select Country/Region</label>
                        <select name="lng" id="lng" *ngIf="!this.appComp.countryListShow" (change)="getCountyAndLangFor($event)" [(ngModel)]="selectedCountry">
                            <option value="{{value.code}}" *ngFor="let value of langs">{{value.name}}</option>
                         </select> 
                    </div> -->
                </div>
            </div>
            <div class="row mt-3 align-items-center">
                <div class="col-md-8">
                    <ul class="footer-links-list">
                        <li
                            *ngFor="
                                let item of appComp?.tenantContent?.footer
                                    ?.links
                            "
                        >
                            <a
                                href="{{ item.url }}"
                                class="link"
                                target="_blank"
                                >{{ item.name }}</a
                            >
                        </li>
                        <!-- <li>Privacy Policy</li>
                        <li>Disclaimer</li>
                        <li>Compliance</li> -->
                    </ul>
                </div>
                <div class="col-md-4" *ngIf="sellertype">
                    <div class="footer-options">
                        <!-- <div class="country-region-mobile">
                            <label>Select Country/Region</label>
                            <br />
                            <select name="" id="">
                                <option value="india">India</option>
                            </select>
                        </div> -->
                        <div ngbDropdown class="d-inline-block">
                            <button
                                class="btn partner-login-btn"
                                id="dropdownBasic1"
                                ngbDropdownToggle
                            >
                                Partner Login
                            </button>
                            <div
                                ngbDropdownMenu
                                aria-labelledby="dropdownBasic1"
                            >
                                <app-partner-login></app-partner-login>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- footer end -->
