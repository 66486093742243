<div class="direction-slider location-enable p-4" id="address-slider">
  <ul #nearestStoresHolder id="nearestStoresHolder">
    <!-- store card 1 -->
    <li *ngFor="let store of localStores">
      <div class="form-sec">
        <p class="fs-18 mb-4"><b class="bg-transparent" style="font-size: 18px;font-family:'gothambold';">
            {{store.name}} </b></p>
        <div class="googlerating d-flex align-items-center" *ngIf="store.averageRating"><img class="mr-2"
            [src]="'/rest/service/images/source/'+sourceId+'/name/google-icon.png'" loading="lazy">

          <star-rating [(value)]="store.averageRating" (totalstars)="5" checkedcolor="#F2C94C"
            uncheckedcolor="lightgray" size="24px" (readonly)=true #starrating>
          </star-rating>
          {{ store.averageRating}}
        </div><br>
        <p class="fs-16 mb-4">{{store.address}}, {{store.city}}, {{store.state}}</p>
        <p class="fs-16 mb-4"> <i class="fas fa-clock"></i> Open until {{store.endTime}} </p>
        <p class="fs-16 mb-4"> <i class="fas fa-directions"></i> {{store.distance}} KM away</p>
        <div class="icon-sec mt-4">
          <ul class="d-flex">
            <li class="mr-4"><a *ngIf="store.maskingNum && store.maskingNum!==''" [href]="'tel:'+store.maskingNum"
                class="d-flex"> <i class="fas fa-phone-alt"></i> Call</a></li>
            <li class="mr-4"><a href="/bookademo?cid=in:other:9o8v9h" target="_blank" class="d-flex"> <i
                  class="fas fa-desktop"></i> Book a<br>Demo</a>
            </li>
            <li><a *ngIf="store.formattedaddress" [href]="'/store/'+store.formattedaddress" target="_blank"
                class="d-flex"> <i class="fas fa-store"></i> View Store<br>Details</a></li>
          </ul>
        </div>
      </div>
    </li>
    <!-- store card 1 end -->

  </ul>
</div>